import React from "react"

const HenryNetzer = (
  <>
  <h4>Captain US Navy (ret)</h4>
    <p>
    Seven years active &amp; 13 years reserve duty. Served on USS Hawkbill SSN 666 based at Pearl Harbor Hawaii. Served as a Navy Civilian for 24 years retiring as the Director Acoustic Research Detachment (ARD), Bayview Idaho in 2007. While Director at ARD Bayview, oversaw the major expansion to include LSV2 Cutthroat which is the Virginia Class Model used to develop the Acoustic Technology that will be integral to the future USS IDAHO SSN 799. 
    </p>
  </>
)

export default HenryNetzer

import React from "react"

const MikeAllen = (
  <>
    <p>
    <strong>Electronic Technician First Class (ET), U.S. Navy (vet)</strong><br />
    </p>
    <p>ET Mike Allen served for eight years on active duty on the USS Boston (SSN703) and at Navsubscol as an instructor. Mike is originally from Boise, Idaho, and currently lives in Woodstock Valley, Connecticut. He is a senior analyst at Staples Corporation.</p>
  </>
)

export default MikeAllen;

import React from "react"

const JayJohnson = (
  <>
    <p>
    <strong>U.S. Navy (ret)</strong><br />
   <ul>
<li>Retired Chairman/CEO General Dynamics </li>
<li>Chief of Naval Operations, 1996-2000</li>
<li>Command tours included US 2nd Fleet, Carrier Group 8, Carrier Air Wing One and VF-84</li>
<li>1968 graduate of the Naval Academy.</li>
<li>Resides in Ketchum ID.</li>
   </ul>
    </p>
  </>
)

export default JayJohnson

import React from "react"
import { Modal, Row, Col } from "react-bootstrap"
import Img from 'gatsby-image'

function CrewModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h3>{props.crewName} <em>{`${props.position}`}</em></h3>
                
            </Modal.Header>
            <Modal.Body>
            <div style={{
                float: 'right',
                marginLeft: '20px'
            }}>
              <img
                src={props.teamMemberPhoto}
                style={{ maxWidth: "250px" }}
                alt={props.teamMember}
                className="rounded"
              />
              
            </div>
            <img src={props.emblem} style={{ maxWidth: "200px", float: 'right' }} />
                {props.modalContent}
                <br />
            </Modal.Body>
      </Modal>
    )
}

export default CrewModal
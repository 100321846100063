import React from "react"

const DonMitchell = (
  <>
  <p><strong>Corporate Attorney/Private Business</strong><br />
  Mr. Mitchell, an Idaho resident for over 50 years, has held legal, business and community positions, including Associate General Counsel for Boise Cascade Corporation, participant in oil and gas exploration, supplier of natural gas requirements to major naval and military installations, and B2B provider of essential manufacturing materials, corporate services and business products. He has served as a Founding Director of the Idaho Humanities Council, a Board Member of the Idaho Botanical Garden, and a past Member of the Vestry of St. Michael’s Cathedral. His interests include general business matters, community, educational and charitable activities.
</p>
  </>
)

export default DonMitchell

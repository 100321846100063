import React from "react"

const SteveArnold = (
  <>
  <h4>Data Systems Specialist First Class (DS1), US Navy Veteran</h4>
    <p>
    Steven Arnold retired in 2016 after more than 28 years of working in the field of Intellectual Property law. From 2014 to 2016, Steve was Chief IP Counsel for Micron Technology, Inc., a Fortune 500 company holding over 22,000 patents. From 2002 to 2014 he served as Chief Counsel for Technology Licensing at Micron. Prior to holding this position, Steve served as Vice President and General Counsel for Micron Electronics, Inc., (Micron PC) a publicly traded Micron Technology subsidiary and a Fortune 1000 manufacturer of personal and business class computers. Steve joined Micron Technology, as Associate General Counsel for Intellectual Property in 1995.</p>
    <p>
Steve began his legal career in 1988 as a litigator with a law firm in Houston, Texas, handling IP litigation matters for clients such as Intel Corporation and Micron Technology, Inc. He holds a Bachelor of Science degree in Mathematics and a law degree, both from the University of Houston, and is a registered patent attorney. He also served for six years in the United States Navy Submarine Service.
    </p>
  </>
)

export default SteveArnold

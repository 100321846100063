import React from "react"

const BarbaraGrant = (
  <>
    <p>
    <strong>Newsletter Contributor</strong><br />
    </p>
    <p>Barbara L. Grant is a contributor to the USS IDAHO Commissioning Committee newsletter. She is a member of the Idaho State Society Daughters of the American Revolution and is their Veterans Affairs Voluntary Services Representative for Idaho.</p>
  </>
)

export default BarbaraGrant

import React from "react"

const MikeMooney = (
  <>
  <p><strong>President, Idaho Region Bank of the Cascades (retired)</strong><br />
  Mr. Mooney retired as President of the Idaho Region of Bank of the Cascades in 2016. Mike Mooney began his banking career in 1974 as a management trainee and has held executive management positions with increasing responsibility throughout his distinguished banking career.  Over the last 42 years, Mooney spent over 20 years as President/CEO and led teams through 14 mergers and acquisitions.</p>
  <p>Mr. Mooney has a BS in Finance and Economics from Utah State University and a MBA from Idaho State University.r of Idaho Business for Education.</p>
  </>
)

export default MikeMooney

import React from "react"

const RickGilchrist = (
  <>
    <p>
    <strong>CAPT, US Navy (ret)</strong><br />
    </p>
    <p>
    Captain Gilchrist served the U.S Navy for 15 years on active and reserve duty. Career highlights include  two cruises aboard the USS Hancock CVA-19 to Vietnam, service as the communications department head at the Naval Air Facility in Sigonella, Sicily and at the operations center of the Commander Naval Forces in Yokosuka, Japan.</p>
    <p>Captain Gilchrist received a Bachelor of Science degree from West Virginia Institute of Technology in biology education and a Masters degree in environmental education with an emphasis on wildlife management.  He ran educational and communications programs about our country’s wildlife for 26 years for the Tennessee Wildlife Resource Agency and the Idaho Department of Fish and Game, Southwest Idaho. He retired from Micron Technology in 2006 after serving for seven years as its safety training coordinator.</p>
  </>
)

export default RickGilchrist;

import React from "react"

const DavidMiskimens = (
  <>
  <h4>US Navy (ret)</h4>
    <p>
    Mr. Miskimens is a Professor of Program Management at the Defense Acquisition University (DAU). Prior to DAU, Mr. Miskimens was the Deputy Program Manager for the Virginia Class Submarine Program Office. Early in his career he worked for the Naval Undersea Warfare Center in Keyport Washington. He holds a BS in Engineering from Washington State University and an MPA from Indiana University – Perdue. He lives with his wife in Coeur d'Alene, Idaho.
    </p>
  </>
)

export default DavidMiskimens

import React from "react"

const ArtClark = (
  <>
    <p>
    <strong>U.S. Navy (ret)</strong><br />
    Rear Admiral Clark retired as the Deputy Director of Operations at the Idaho National Lab. In his 35 year Navy career he served as Atlantic Fleet Maintenance Director, Commander Puget Sound Naval Shipyard,
and served two tours in Vietnam.
    </p>
  </>
)

export default ArtClark


import React from "react"
import { Row, Col } from "react-bootstrap"
import AdivsoryBoardModalButton from "./AdvisoryBoardModalButton"

import {
  AndyKrivy,
  DirkKempthorne,
  RichardColburn,
  RichardFife,
  SteveArnold,
  LanceKolbet,
  HenryNetzer,
  RichardMcPherson,
  MartySattison,
  RayFortin,
  TomDale,
  RobertSkinner,
  AndrewJohnson,
  RussellJohnson
} from "./AdvisoryBoardMembers"

import GoldDolphin from "../../images/gold-dolphin.jpg"
import SilverDolphin from "../../images/silver-dolphin.jpg"
import SupplyOfficer from "../../images/supply-officer.jpg"
import RichardColburnPhoto from "../../images/RichardColburn.jpg"
import SteveArnoldPhoto from "../../images/SteveArnold.jpg"
import HenryNetzerPhoto from "../../images/HenryNetzer.jpg"
import MartySattisonPhoto from "../../images/MartySattison.jpg"
import RichardFifePhoto from "../../images/RichardFife.jpg"
import AndyKrivyPhoto from "../../images/AndyKrivy.jpg"
import RayFortinPhoto from "../../images/RayFortin.jpg"
import TomDalePhoto from "../../images/TomDale.jpg"
import RobertSkinnerPhoto from "../../images/RobertSkinner.jpg"
import RichardMcPhersonPhoto from "../../images/RichardMcPherson.jpg"
import AndrewJohnsonPhoto from "../../images/AndrewJohnson.jpg"
import RussellJohnsonPhoto from "../../images/RussellJohnson.jpg"

const BoardOfDirectors = () => (
  <>
    <Row className="my-4">
      <Col md={6} className="my-2">
        <AdivsoryBoardModalButton
          modalContent={RichardColburn}
          crewName="Richard Colburn"
          position="Chairman of the Board"
          teamMemberPhoto={RichardColburnPhoto}
          rank={
            <>
              Co-Founder
              <br />
              CAPT US Navy (ret)
            </>
          }
          emblem={GoldDolphin}
        />
      </Col>
      <Col md={6}className="my-2">
        <AdivsoryBoardModalButton
          modalContent={RayFortin}
          crewName="Ray Fortin"
          position="Treasurer"
          teamMemberPhoto={RayFortinPhoto}
          rank="CDR, US Navy Veteran"
          emblem={SupplyOfficer}
        />
      </Col>
      <Col md={6}className="my-2">
        <AdivsoryBoardModalButton
          modalContent={AndyKrivy}
          crewName="Andy Krivy"
          position="Secretary"
          teamMemberPhoto={AndyKrivyPhoto}
          rank={
            <>
              Co-Founder
              <br />
              Fire Control Technician Ballistic Second Class (FTB2), US Navy
              Veteran
            </>
          }
          emblem={SilverDolphin}
        />
      </Col>

      <Col md={6}className="my-2">
        <AdivsoryBoardModalButton
          modalContent={HenryNetzer}
          crewName="Henry Netzer"
          position="North Idaho Regional Chair"
          teamMemberPhoto={HenryNetzerPhoto}
          rank="Captain US Navy (ret)"
          emblem={GoldDolphin}
        />
      </Col>
      <Col md={6}className="my-2">
        <AdivsoryBoardModalButton
          modalContent={TomDale}
          crewName="Tom Dale"
          position="Southwest Idaho Regional Chair"
          teamMemberPhoto={TomDalePhoto}
          rank="Musician Second Class (MU2) US Navy Veteran MU2 "
          //emblem={SilverDolphin}
        />
      </Col>
      <Col md={6}className="my-2">
        <AdivsoryBoardModalButton
          modalContent={RussellJohnson}
          crewName="Russel Johnson"
          position="Southeast Idaho Regional Chair"
          teamMemberPhoto={RussellJohnsonPhoto}
        />
      </Col>
      <Col md={6}className="my-2">
        <AdivsoryBoardModalButton
          modalContent={AndrewJohnson}
          crewName="Andrew Johnson esq."
          position="General Counsel"
          teamMemberPhoto={AndrewJohnsonPhoto}
          rank={
            <>
              SSGT US Marine Corps Veteran
            </>
          }
        />
      </Col>
      <Col md={6}className="my-2">
        <AdivsoryBoardModalButton
          modalContent={RichardFife}
          crewName="Richard Fife"
          position="Chairman Emeritus"
          teamMemberPhoto={RichardFifePhoto}
          rank={
            <>
              Co-Founder
              <br />
              LCDR US Navy (ret)
            </>
          }
          emblem={GoldDolphin}
        />
      </Col>
    </Row>
    <Row>
    <Col md={6}className="my-2">
        <h4>Vice Chairman</h4>
        <h5>Open</h5>
      </Col>
      {/* <Col md={6}className="my-2">
        <h4>General Counsel</h4>
        <h5>Open</h5>
      </Col> */}
      <Col md={6}className="my-2">
        <h4>Southcentral Idaho Regional Chair</h4>
        <h5>Open</h5>
      </Col>
    </Row>
  </>
)

export default BoardOfDirectors

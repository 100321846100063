import React from "react"

const GeraldGneckow = (
  <>
    <p>
    <strong>U.S. Navy (ret)</strong><br />
    <ul>
<li>Commanding Officer USS Plunger (SSN 595) and two Submarine Groups during the Cold War</li>
<li>Principal Deputy to Undersecretary of Defense for Acquisition, Technology and Logistics</li>
<li>Director of Management and Budget for Coalition Provisional Forces in Iraq</li>
<li>Chief Operating Officer for Airbus in the US</li>
    </ul>
    </p>
  </>
)

export default GeraldGneckow

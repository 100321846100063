import React from "react"

const BarryMcCahill = (
  <>
  <h4>Public Relations Official</h4>
    <p>
    Barry McCahill retired with distinction in 1996 from the National Highway Traffic Safety Administration (NHTSA) where he headed the motor vehicle regulatory agency’s communications office.  Subsequently he was Senior Counselor for Stratacomm, a Washington, DC public affairs firm where he represented a variety of national/international companies, and headed two national coalitions to shape domestic public policy. He has extensive experience as an interview source and spokesman for national television, radio and print news stories. He is the public policy consultant for the National Association for Pupil Transportation representing the yellow school bus industry. During the Vietnam era Mr. McCahill was an Army officer assigned to U.S. Army Security Agency headquarters.</p>
    <p>McCahill is a graduate of the George Mason College of the University of Virginia, and Army Engineer Officer Candidate School. He holds a U.S. Coast Guard Master license.
    </p>
  </>
)

export default BarryMcCahill

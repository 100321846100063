import React from "react"

const LanceKolbet = (
  <>
  <h4>Interior Communications, US Navy Veteran</h4>
    <p>
    Info coming soon.
    </p>
  </>
)

export default LanceKolbet

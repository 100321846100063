import React from 'react'
import { Row, Col } from 'react-bootstrap'
import AdivsoryBoardModalButton from './AdvisoryBoardModalButton'

import {
     DirkKempthorne,
     DavidLehman,
     DennisJohnson,
     LindaOliver,
     JayJohnson,
     JohnGroseenbacher, 
     GeraldGneckow,
     DavidOliver,
     ParkPrice,
     MikeMooney,
     SueThilo,
     RayStark,
     CandiceAllphin,
     TomWilford,
     RonEythchison,
     GarySayler,
     BillShawver,
     SteveHillis,
     JackBurdick,
     ArtClark,
     MichaelCoyle,
     PhilReberger,
     DavidMericle,
     BillPrevity,
     DonMitchell,
     ScottTurlington,
     DougArmstrong,
     BarryMcCahill,
     MikeJohnson,
     DavidMiskimens,
     LauraBarton,
     GregoryMcDonald,
 } from './AdvisoryBoardMembers'
 
 import GoldDolphin from "../../images/gold-dolphin.jpg"
 import SilverDolphin from "../../images/silver-dolphin.jpg"
 import NavalAviator from "../../images/NavalAviatorBadge.jpg"
 import ShipEmblem from "../../images/ShipEmblem.jpg"
 import AirForceEmblem from "../../images/AirForceEmblem.jpg"
 import AirForceEmblem2 from "../../images/AirForceEmblem2.jpg"
 import DualEmblem from "../../images/DualEmblem.jpg"

 import DirkKempthornePhoto from "../../images/dirk-kempthorne.jpg"
 import DavidLehmanPhoto from "../../images/david-lehman.jpg"
 import DennisJohnsonPhoto from "../../images/dennis_johnson.jpg"
 import LindaOliverPhoto from "../../images/LindaOliver.jpg"
 import JayJohnsonPhoto from "../../images/JayJohnson.jpg"
 import JohnGroseenbacherPhoto from "../../images/JohnGrossenbacher.jpg"
 import GeraldGneckowPhoto from "../../images/GeraldGneckow.jpg"
 import DavidOliverPhoto from "../../images/DavidOliver.jpg"
 import ParkPricePhoto from "../../images/ParkPrice.jpg"
 import MikeMooneyPhoto from "../../images/MikeMooney.jpg"
 import SueThiloPhoto from "../../images/SueThilo.jpg"
 import RayStarkPhoto from "../../images/RayStark.jpg"
 import CandiceAllphinPhoto from "../../images/CandiceAllphin.jpg"
 import TomWilfordPhoto from "../../images/TomWilford.jpg"
 import RonEytchisonPhoto from "../../images/RonEytchison.jpg"
 import GarySaylerPhoto from "../../images/GarySayler.jpg"
 import BillShawverPhoto from "../../images/BillShawver.jpg"
 import SteveHillisPhoto from "../../images/SteveHillis.jpg"
 import JackBurdickPhoto from "../../images/JackBurdick.jpg"
 import ArtClarkPhoto from "../../images/ArtClark.jpg"
 import MichaelCoylePhoto from "../../images/MichaelCoyle.jpg"
 import PhilRebergerPhoto from "../../images/PhilReberger.jpg"
 import DavidMericlePhoto from "../../images/DavidMericle.jpg"
 import BillPrevityPhoto from "../../images/BillPrevity.jpg"
 import DonMitchellPhoto from "../../images/DonMitchell.jpg"
 import ScottTurlingtonPhoto from "../../images/ScottTurlington.jpg"
 import DougArmstrongPhoto from "../../images/DougArmstrong.jpg"
 import BarryMcCahillPhoto from "../../images/BarryMcCahill.jpg"
 import MikeJohnsonPhoto from "../../images/MikeJohnson.jpg"
 import DavidMiskimensPhoto from '../../images/David-Miskimens.jpg'
 import GregoryMcDonaldPhoto from '../../images/GregoryMcDonald.jpg'
 


const AdvisoryBoard = () => (
    <>
    <Row>
        <Col sm={12} md={6}   className="my-2">
        <AdivsoryBoardModalButton
                modalContent={DirkKempthorne}
                crewName="Honorable Dirk Kempthorne"
                rank="Advisory Board"
                position="Chairman"
                teamMemberPhoto={DirkKempthornePhoto}
                teamMember="Honorable Dirk Kempthorne"
            />        
        </Col>
        <Col sm={12} md={6}    className="my-2">
        <AdivsoryBoardModalButton
                modalContent={DavidLehman}
                crewName="David Lehman"
                rank="Advisory Board"
                position="Vice Chairman"
                teamMemberPhoto={DavidLehmanPhoto}
                teamMember="David Lehman"
            /> 
        </Col>
        <Col sm={12} md={6}    className="my-2">
        <AdivsoryBoardModalButton
                modalContent={DennisJohnson}
                crewName="Dennis Johnson"
                rank='Chairman - Corporate Fundraising "SUB" Committee'
                position=""
                teamMemberPhoto={DennisJohnsonPhoto}
                teamMember="Dennis Johnson"
            />
        </Col>
        <Col sm={12} md={6}    className="my-2">
        <AdivsoryBoardModalButton
                modalContent={TomWilford}
                crewName="Tom Wilford"
                rank='Chairman - Audit "SUB" Committee'
                position=""
                teamMemberPhoto={TomWilfordPhoto}
                teamMember="Tom Wilford"
            />
        </Col>

    <Col sm={12} md={6}    className="my-2">
        <AdivsoryBoardModalButton
                modalContent={LindaOliver}
                crewName="Linda Oliver"
                rank='Crew and Family Support "SUB" Committee'
                position="Chairman"
                teamMemberPhoto={LindaOliverPhoto}
                teamMember="Linda Oliver"
            />
        </Col>
        <Col sm={12} md={6}    className="my-2">
        <AdivsoryBoardModalButton
                modalContent={JayJohnson}
                crewName="ADM Jay L Johnson"
                rank='US Navy (ret)'
                position=""
                teamMemberPhoto={JayJohnsonPhoto}
                teamMember="ADM Jay L Johnson"
                emblem={NavalAviator}
            />
        </Col>
        <Col sm={12} md={6}    className="my-2">
        <AdivsoryBoardModalButton
                modalContent={JohnGroseenbacher}
                crewName="VADM John Grossenbacher"
                rank='US Navy (ret)'
                position=""
                teamMemberPhoto={JohnGroseenbacherPhoto}
                teamMember="VADM John Grossenbacher"
                emblem={GoldDolphin}
            />
        </Col>
        <Col sm={12} md={6}    className="my-2">
        <AdivsoryBoardModalButton
                modalContent={RonEythchison}
                crewName="VADM Ron Eytchison"
                rank='US Navy (ret)'
                position=""
                teamMemberPhoto={RonEytchisonPhoto}
                teamMember="VADM Ron Eythcison"
                emblem={GoldDolphin}
            />
        </Col>
    <Col sm={12} md={6}    className="my-2">
        <AdivsoryBoardModalButton
                modalContent={GeraldGneckow}
                crewName="RADM Gerald Gneckow"
                rank='US Navy (ret)'
                position=""
                teamMemberPhoto={GeraldGneckowPhoto}
                teamMember="RADM Gerald Gneckow"
                emblem={ShipEmblem}
            />
        </Col>

        <Col sm={12} md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={DavidOliver}
                crewName="RADM David Oliver"
                rank='US Navy (ret)'
                position=""
                teamMemberPhoto={DavidOliverPhoto}
                teamMember="RADM David Oliver"
                emblem={GoldDolphin}
            />
        </Col>

        <Col sm={12} md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={MichaelCoyle}
                crewName="RADM Michael T. Coyle"
                rank='US Navy (ret)'
                position=""
                teamMemberPhoto={MichaelCoylePhoto}
                teamMember="RADM Michael T. Coyle"
                emblem={GoldDolphin}
            />
        </Col>

        <Col sm={12} md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={ArtClark}
                crewName="RADM Art Clark"
                rank='US Navy (ret)'
                position=""
                teamMemberPhoto={ArtClarkPhoto}
                teamMember="RADM Art Clark"
                //emblem={GoldDolphin}
            />
        </Col>

        <Col sm={12} md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={GarySayler}
                crewName="Maj Gen Gary Sayler"
                rank='US Air Force (ret)'
                position=""
                teamMemberPhoto={GarySaylerPhoto}
                teamMember="Maj Gen Gary Sayler"
                emblem={AirForceEmblem}
            />
        </Col>
        <Col sm={12} md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={BillShawver}
                crewName="Brig Gen Bill Shawver"
                rank='US Air Force (ret)'
                position=""
                teamMemberPhoto={BillShawverPhoto}
                teamMember="Brig Gen Bill Shawver"
                emblem={AirForceEmblem2}
            />
        </Col>

        <Col sm={12} md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={PhilReberger}
                crewName="CAPT Phil Reberger"
                rank='US Navy (ret)'
                position=""
                teamMemberPhoto={PhilRebergerPhoto}
                teamMember="CAPT Phil Rebeger"
                emblem={NavalAviator}
            />
        </Col>

        <Col sm={12} md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={DavidMericle}
                crewName="CAPT David Mericle"
                rank='US Navy (ret)'
                position=""
                teamMemberPhoto={DavidMericlePhoto}
                teamMember="CAPT David Mericle"
                emblem={GoldDolphin}
            />
        </Col>
        
        <Col sm={12} md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={DavidMiskimens}
                crewName="David Miskimens"
                rank='US Navy (ret)'
                position=""
                teamMemberPhoto={DavidMiskimensPhoto}
                teamMember="DavidMiskimens"
                //emblem={GoldDolphin}
            />
        </Col>

        <Col sm={12} md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={BillPrevity}
                crewName="CAPT Bill Previty"
                rank='US Navy (ret)'
                position=""
                teamMemberPhoto={BillPrevityPhoto}
                teamMember="CAPT Bill Previty"
                emblem={GoldDolphin}
            />
        </Col>

        <Col sm={12} md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={SteveHillis}
                crewName="FLTCM (SS, AW) Steve Hillis"
                rank='US Navy (ret)'
                position="Crew/Family Support SUB Committee"
                teamMemberPhoto={SteveHillisPhoto}
                teamMember="FLTCM (SS, AW) Steve Hillis"
                emblem={DualEmblem}
            />
        </Col>
        <Col sm={12} md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={JackBurdick}
                crewName="MCPO (SS) Jack Burdick"
                rank='US Navy (ret)'
                position="Crew/Family Support SUB Committee"
                teamMemberPhoto={JackBurdickPhoto}
                teamMember="MCPO (SS) Jack Burdick"
                emblem={SilverDolphin}
            />
        </Col>
        <Col md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={ParkPrice}
                crewName="Park Price"
                rank='Corporate Fundraising "SUB" Committee'
                position=""
                teamMemberPhoto={ParkPricePhoto}
                teamMember="Park Price"
                emblem={ShipEmblem}
            />
        </Col>
        <Col md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={MikeMooney}
                crewName="Mike Mooney"
                rank='Corporate Fundraising "SUB" Committee'
                position=""
                teamMemberPhoto={MikeMooneyPhoto}
                teamMember="Mike Mooney"
            />
        </Col>
        <Col md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={DougArmstrong}
                crewName="Doug Armstrong"
                rank='Corporate Fundraising "SUB" Committee'
                position=""
                teamMemberPhoto={DougArmstrongPhoto}
                teamMember="Doug Armstrong"
            />
        </Col>
        <Col md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={SueThilo}
                crewName="Sue Thilo"
                rank='Corporate Fundraising "SUB" Committee'
                position=""
                teamMemberPhoto={SueThiloPhoto}
                teamMember="Sue Thilo"
            />
        </Col>
        <Col md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={RayStark}
                crewName="Ray Stark"
                rank='Corporate Fundraising "SUB" Committee'
                position=""
                teamMemberPhoto={RayStarkPhoto}
                teamMember="Ray Stark"
            />
        </Col>
        <Col md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={CandiceAllphin}
                crewName="Candice Allphin"
                rank='Corporate Fundraising "SUB" Committee'
                position=""
                teamMemberPhoto={CandiceAllphinPhoto}
                teamMember="Candice Allphin"
            />
        </Col>
        <Col md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={BarryMcCahill}
                crewName="Barry McCahill"
                rank='Public Relations Official'
                position=""
                teamMemberPhoto={BarryMcCahillPhoto}
                teamMember="Barry McCahill"
            />
        </Col>

        <Col md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={DonMitchell}
                crewName="Don Mitchell"
                rank='Corporate Fundraising "SUB" Committee'
                position=""
                teamMemberPhoto={DonMitchellPhoto}
                teamMember="Don Mitchell"
            />
        </Col>

        <Col md={6}  className="my-2">
        <AdivsoryBoardModalButton
                modalContent={ScottTurlington}
                crewName="Scott Turlington"
                rank='Corporate Fundraising "SUB" Committee'
                position=""
                teamMemberPhoto={ScottTurlingtonPhoto}
                teamMember="Scott Turlington"
            />
        </Col>
        <Col md={6} className="my-2">
        <AdivsoryBoardModalButton
                modalContent={MikeJohnson}
                crewName="Mike Johnson"
                rank=""
                position=""
                teamMemberPhoto={MikeJohnsonPhoto}
                teamMember="Mike Johnson"
            />
        </Col>
        <Col md={6} className="my-2">
        <AdivsoryBoardModalButton
                modalContent={GregoryMcDonald}
                crewName="Gregory McDonald"
                rank=""
                position=""
                teamMemberPhoto={GregoryMcDonaldPhoto}
                teamMember="Gregory McDonald"
                emblem={ShipEmblem}
            />
        </Col>
       </Row>
    </>
)

export default AdvisoryBoard
import React from "react"

const MartySattison = (
  <>
  <h4>Captain US Navy (ret)</h4>
    <p>
    US Naval Academy 1977. Six years active &amp; 20 years reserve duty. Served on USS Plunger SSN 595. 32 years at Idaho National Lab retiring as the Director, Nuclear Safety and Regulatory Research Division of Battelle Energy Alliance. Currently the Chair for the Idaho Section of the American Nuclear Society. Lives in Idaho Falls Idaho.
    </p>
  </>
)

export default MartySattison

import React from "react"

const GarySayler = (
  <>
  <h4>Maj Gen U.S. Air Force (ret)</h4>
    <p>
    President of the Idaho Defense Alliance. Senior positions of command include Operations Group Commander, Wing Commander, Assistant Adjutant General-Air, Joint Task Force Commander, and Adjutant General, Idaho responsible for the Idaho National Guard and serving as the Governors Homeland Security Advisor. In addition to the Idaho Defense Alliance, Maj Gen Sayler serves as a Boise Airport Commissioner and is a member of the Veterans of Foreign Wars, National Guard Association and the U.S. Air Force Association.
    </p>
  </>
)

export default GarySayler

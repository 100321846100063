import React from "react"

const GeraldGneckow = (
  <>
    <p>
    <strong>U.S. Navy (ret)</strong><br />
    Rear Admiral Gneckow is a native of Boise ID, graduating from Boise High School in 1956 and then went on to graduate from the University of Idaho in 1960 where he was a member of Beta Theta Pi Fraternity. In his 30 year Navy career he commanded USS Iowa BB61, USS Richmond K Turner CG20 and USS John S McCain DDG36. </p>
    <p>Rear Admiral Gneckow currently resides in Homosassa Florida.
    </p>
  </>
)

export default GeraldGneckow
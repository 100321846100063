import React from "react"

const ParkPrice = (
  <>
  <p><strong>Board Chairman - Director Bank of Idaho</strong><br />
    
    Mr. Price is Chairman of the Board of Directors of Bank of Idaho.  He holds a bachelor’s degree in Economics from Dartmouth College where he was enrolled in NROTC.  Upon graduation, he served 2 years as a Naval Officer aboard a destroyer in the Atlantic Fleet. Mr. Price serves as a Trustee for the Public Employees Retirement System of Idaho (PERSI) and as Chairman of the Board of Trustees of the College of Eastern Idaho as well as the Eastern Regional Chair of Idaho Business for Education.  Mr. Price recently completed his second term as a Director of the Salt Lake City Branch of the Federal Reserve Bank of San Francisco.

    </p>
  </>
)

export default ParkPrice

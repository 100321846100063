import React from "react"

const RayFortin = (
  <>
  <h4>CDR, US Navy Veteran</h4>
    <p>
    Info coming soon.
    </p>
  </>
)

export default RayFortin

import React from "react"

const BillPrevity = (
  <>
    <p>
    <strong>U.S. Navy (ret)</strong><br />
    1965 Naval Academy graduate</p>
    <p>Commanded USS SAN FRANCISCO (SSN711), Commander Submarine Squadron 22, and served as Deputy Director Test Evaluation and Technology on CNO Staff during 29 year naval career</p>
    <p>Principal consultant for nuclear remediation projects at various sites in U.S. and U.K.</p>
    <p>Director of Safety and Health at Fluor Hanford</p>
    <p>Currently resides in Boise</p>
  </>
)

export default BillPrevity

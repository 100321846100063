import React from "react"

const GayleAlvarez = (
  <>
   <p>
                  <ul>
<li>Founding member of Idaho Military Historical Society (IMHS) and Museum, served on the Board of Directors for 23 years. </li>
<li>Researched and Produced Pass In Review, the IMHS’ newsletter for 17 years. </li>
<li>Founding member and Board member of Medal of Honor Historical Society of the US.  Have researched Idaho’s Medal of Honor recipients for 22 years.</li>
<li>Retired from Idaho Military Division after 34 years working in both Army and Air Force offices. </li>
<li>Served 13 years in the Idaho National Guard rising to rank of Sergeant First Class.</li>
<li>Co-author of Images of America, Farragut Naval Training Station</li>
<li>Editor and co-author of The History of the 148th Field Artillery 1940-1946, an Idaho unit that served in WWII.</li>
                  </ul>
                </p>
  </>
)

export default GayleAlvarez

import React from "react"

const DirkKempthorne = (
  <>
    <p>
    <strong>Honorable Dirk Kempthorne</strong>
    <ul>
<li>Chairman Advisory Board</li>
<li>30th Governor of Idaho</li>
<li>49th U.S. Secretary of the Interior</li>
<li>U.S. Senator from Idaho</li>
<li>Mayor of Boise</li>
    </ul>

    </p>
  </>
)

export default DirkKempthorne

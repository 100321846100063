import React from "react"

const CandiceAllphin = (
  <>
  <p><strong>Senior Vice President U.S. Bank (Retired)</strong><br />
  Candi Allphin (a Boise Valley Native), is a retired Senior Vice President, U.S. Bank.  She spent 38 years in the banking industry in leadership roles.  Candi holds a BBA in Management from Boise State University and is a graduate of the Pacific Coast Banking School at the University of Washington.  She is a National Association of Corporate Directors Fellow and is a Director for Idaho Cares Plus a subsidiary of Blue Cross of Idaho. 
</p>
  </>
)

export default CandiceAllphin

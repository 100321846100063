import React from "react"

const RussellJohnson = (
  <>
    <p>Russell has a long and successful business career in the Idaho Falls area and a long and distinguished career of service with Rotary International.</p>
    <p>While Russell may not be a veteran, he is no stranger to the Navy. In 2017 he was the Chairman of the Board for the Idaho Falls Extreme Blue Thunder Air Show featuring the US Navy Blue Angels. Through Russell's outstanding leadership, the Idaho Falls Extreme Blue Thunder Air Show was awarded the prestigious Blue Angel Air Show of the Year award for 2017... and was considered the best of 34 Blue Angel performance locations during 2017.</p>
    <p><a href="https://www.eastidahonews.com/2017/12/extreme-blue-thunder-air-show-named-2017-air-show-year/" target="_blank" rel="noopener noreferrer">https://www.eastidahonews.com/2017/12/extreme-blue-thunder-air-show-named-2017-air-show-year/</a></p>
    <p>According to the US Navy Blue Angels, the award is given annually to a show site that best demonstrates the upper most echelon in Tactical and Operational readiness of the air show site.</p>
    <p>For his leadership and teams' success, Russell was also made an HONORARY NAVAL Aviator by NAVAIR!</p>
  </>
)

export default RussellJohnson;

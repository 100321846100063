import React from "react"
import { Row, Col } from "react-bootstrap"
import AdivsoryBoardModalButton from "./AdvisoryBoardModalButton"

import {
  TimMarsano,
  MikeFeeley,
  BarryOglesbee,
  GayleAlvarez,
  MelanieNeilson,
  RobertEarle,
  LauraBarton,
  BrettBlethen,
  MarkusGundmundsson,
  RobertBrady,
  BarbaraGrant,
  MikeAllen,
  MatthewBarton,
  RickGilchrist,
} from "./AdvisoryBoardMembers"

import NavalAviator from "../../images/NavalAviatorBadge.jpg"
import GoldDolphin from "../../images/gold-dolphin.jpg"
import SilverDolphin from "../../images/silver-dolphin.jpg"
import AirForceDevice from "../../images/AirForceDevice.jpg"
import ShipEmblem from "../../images/ShipEmblem.jpg"
import SupplyOfficer from "../../images/supply-officer.jpg"
import TimMarsanoPhoto from "../../images/TimMarsano.jpg"
import MikeFeeleyPhoto from "../../images/MikeFeeley.jpg"
import GayleAlvarezPhoto from "../../images/GayleAlvarez.jpg"
import RufusTaylorPhoto from "../../images/RufusTaylor.jpg"
import DonCurryPhoto from "../../images/DonCurry.jpg"
import BarryOglesbeePhoto from "../../images/BarryOglesbee.jpg"
import MelanieNielsonPhoto from "../../images/MelanieNielson.jpg"
import ScottRumphPhoto from "../../images/ScottRumph.jpg"
import JimMcMahonPhoto from "../../images/JimMcMahon.jpg"
import MichaelSatzPhoto from "../../images/MichaelSatz.jpg"
import RobertEarlePhoto from "../../images/RobertEarle.jpg"
import RickGilchristPhoto from "../../images/RickGilchrist.jpg"
import LauraBartonPhoto from '../../images/Laura-White-Barton.jpg'
import BrettBlethenPhoto from '../../images/BrettBlethen.jpg'
import MarkusGundmundssonPhoto from '../../images/MarkusGundmundsson.jpg'
import RobertBradyPhoto from '../../images/RobertBrady.jpg'
import BarbaraGrantPhoto from '../../images/BarbaraGrant.jpg'
import MikeAllenPhoto from '../../images/MikeAllen.jpg'
import MatthewBartonPhoto from '../../images/MatthewBarton.jpg'

const ExecutiveCommittees = () => (
  <>
    <Row className="mb-2">
      <Col md={6} >
        <h5>Marketing &amp; Public Affairs Committee</h5>
        <div
          className="mb-4"
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <div className="mb-4">
            <AdivsoryBoardModalButton
              modalContent={LauraBarton}
              crewName="Laura Barton"
              position="Marketing &amp; Public Affairs Committee Chair"
              teamMemberPhoto={LauraBartonPhoto}
              //emblem={GoldDolphin}
            />
          </div>
        </div>
        
        <div
          className="mb-4"
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <div className="mb-4">
            <AdivsoryBoardModalButton
              modalContent={MarkusGundmundsson}
              crewName="Markus Gudmundsson"
              position="Marketing &amp; Public Affairs Committee Vice Chair"
              teamMemberPhoto={MarkusGundmundssonPhoto}
              emblem={NavalAviator}
            />
          </div>
        </div>

        <div className="mb-4">
          <AdivsoryBoardModalButton
            modalContent={TimMarsano}
            crewName="Tim Marsano"
            position="Public Affairs Officer"
            teamMemberPhoto={TimMarsanoPhoto}
            rank="Col USAF (ret)"
            //emblem={GoldDolphin}
          />
        </div>
        <div className="mb-4">
          <AdivsoryBoardModalButton
            modalContent={GayleAlvarez}
            crewName="Gayle Alvarez"
            position="Historian"
            teamMemberPhoto={GayleAlvarezPhoto}
          />
        </div>
       <div
          className="mb-4"
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <div className="mb-4">
            <AdivsoryBoardModalButton
              modalContent={RobertBrady}
              crewName="Robert Brady"
              position="Newsletter Editor"
              teamMemberPhoto={RobertBradyPhoto}
              emblem={SilverDolphin}
            />
          </div>
        </div>
        <div className="mb-4">
        <AdivsoryBoardModalButton
          modalContent={BarryOglesbee}
          crewName="Dr. Barry Oglesbee DC"
          position="Merchandise Manager"
          teamMemberPhoto={BarryOglesbeePhoto}
          rank="U.S. Navy (vet)"
          emblem={SilverDolphin}
        />
      </div>
      <div
          className="mb-4"
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <div className="mb-4">
            <AdivsoryBoardModalButton
              modalContent={MikeAllen}
              crewName="Mike Allen"
              position="Special Product Manager"
              teamMemberPhoto={MikeAllenPhoto}
              emblem={SilverDolphin}
            />
          </div>
        </div>
        <div
          className="mb-4"
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <div className="mb-4">
            <AdivsoryBoardModalButton
              modalContent={RickGilchrist}
              crewName="Rick Gilchrist"
              position="Newsletter Contributor"
              teamMemberPhoto={RickGilchristPhoto}
              emblem={ShipEmblem}
            />
          </div>
        </div>
        <div className="mb-4">
          <AdivsoryBoardModalButton
            modalContent={BarbaraGrant}
            crewName="Barbara Grant"
            position="Newsletter Contributor"
            teamMemberPhoto={BarbaraGrantPhoto}
          />
        </div>
        <div className="mb-4">
          <AdivsoryBoardModalButton
            modalContent={MatthewBarton}
            crewName="Matthew Barton"
            position="Social Media Manager"
            teamMemberPhoto={MatthewBartonPhoto}
          />
        </div>
      </Col>
      <Col md={6} >
        <h5>Planning Committee</h5>

                <div
          className="mb-4"
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "100px",
              marginRight: "10px",
            }}
          >
            <img
              className="rounded "
              src={DonCurryPhoto}
              style={{
                width: "100px",
              }}
            />
          </div>
          <div>
            <p>
              <strong>Don Curry</strong>
              <br />
              <em>Chairman Planning Committee</em>
              <br />
              EMC US US Navy (ret)
            </p>
          </div>
          <div style={{ width: "120px" }}>
            <img src={SilverDolphin} style={{ width: "120px" }} />
          </div>
        </div>
        <div
          className="mb-4"
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "100px",
              marginRight: "10px",
            }}
          >
            <img
              className="rounded "
              src={JimMcMahonPhoto}
              style={{
                width: "100px",
              }}
            />
          </div>
          <div>
            <p>
              <strong>Jim McMahon</strong>
              <br />
              <em>Vice Chair Planning Committee</em>
              <br />
              CDR US Navy (ret)
            </p>
          </div>
          {/* <div style={{ width: "120px" }}>
            <img src={GoldDolphin} style={{ width: "120px" }} />
          </div> */}
        </div>
        <h5>Membership Committee</h5>
        <em>Chair Membership Committee</em>
      <h6>Open</h6>
        
        
        <div
          className="mb-4"
          style={{
            width: "100%",
            display: "flex",
          }}
        >
        </div>
        <div className="mt-4">
          <AdivsoryBoardModalButton
            modalContent={BrettBlethen}
            crewName="Brett Blethen"
            position="Vice Chair Membership Committee"
            teamMemberPhoto={BrettBlethenPhoto}
            rank="Maj US Air Force (ret)"
            emblem={AirForceDevice}
          />
        </div>


       
      </Col>
      <Col md={6} >
        <h5>Fundraising Committee</h5>
        <em>Chair Fundraising Committee</em>
      <h6>Open</h6>
       
        <div className="mt-4">
          <h5>Finance Committee</h5>
          <AdivsoryBoardModalButton
            modalContent={RobertEarle}
            crewName="Robert Earle"
            position="Chairman Finance Committee"
            teamMemberPhoto={RobertEarlePhoto}
            rank="LCDR US Navy Veteran"
            emblem={GoldDolphin}
          />
        </div>
      </Col>
    </Row>

 </>
)

export default ExecutiveCommittees

import React from "react"

const TomDale = (
  <>
  <h4>Musician Second Class (MU2) US Navy Veteran MU2</h4>
    <p>
    While in the Navy Tom Served on the USS Forrestal and USS Intrepid.</p>
    <p>
After the Navy, Tom received his BA-ME from Northwest Nazarene University and a MME from University of North Texas. Tom taught High School for eight years, served on the Nampa City Council for six years and then as Mayor of Nampa for 12 years. Tom recently retired from Public Service after six years as a Canyon County Commissioner.
    </p>
  </>
)

export default TomDale

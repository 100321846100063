import React from "react"

const BrettBlethen = (
  <>
    <p>
    Twenty-two years active duty (7 years enlisted, 15 years officer). Served in various duties working with Minuteman Missiles, nuclear and conventional munitions safety, and aircraft maintenance management. Hometown Milo, Maine. Eastern Illinois University BA in General Education (automotive and electronics emphasis). Master’s in public Administration from Troy State University. Twenty-three years with Micron Technology, high tech operations, manufacturing of memory modules.
    </p>
  </>
)

export default BrettBlethen

import React from "react"

const DavidLehman = (
  <>
    <p>
    <strong>Principal at Primus Policy Group</strong><br />
    Experienced government affairs, public policy, and public relations professional with executive-level management experience at the state and federal levels of government. Deeply knowledgeable in the budgeting, policy-making, and government contracting aspects of government, at all levels

    </p>
  </>
)

export default DavidLehman

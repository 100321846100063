import React from "react"

const MichaelCoyle = (
  <>
    <p>
    <strong>U.S. Navy (ret)</strong><br />
    Rear Admiral Coyle served in nuclear submarines before transitioning to the Navy’s Engineering Duty Officer program and eventually commanded the Pearl Harbor, Hawaii and Mare Island, California Naval Shipyards. Following retirement from the Navy in 1998 he worked in the commercial nuclear power industry where he was the Site Vice President at the Clinton, Illinois Nuclear Power Station and later, the Site Vice President at the Cooper Nuclear Plant in Brownville, Nebraska. From 2009 to 2019 he was an employee and consultant to several contractors performing work for the Department of Energy.
    </p>
  </>
)

export default MichaelCoyle

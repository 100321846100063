import React from "react"

const MatthewBarton = (
  <>
    <p>
    <strong>Social Media Manager</strong><br />
    </p>
    <p>Matthew Barton is a junior at Boise High School. He is a member of the Boy Scouts of America Troop 100 and Leadership Boise Academy Class of 2024. Matthew has competed in the sport of fencing since age 9 and competed in the Junior Olympics Championships for fencing in 2022, 2023 and 2024. Additionally, Matthew enjoys snow skiing, snowmobiling, engineering and drawing.</p>
  </>
)

export default MatthewBarton;

import React from "react"

const TomWilford = (
  <>
    <p>
    <strong>Chairman - Audit "SUB" Committee</strong><br />
    Tom is currently retired. Over the past 50 years, he served in various capacities with the Ernst &amp; Young and its predecessors, in the areas of audit, tax and management consulting at its offices in Chicago, Boise and Anchorage. He retired as Managing Partner of the Anchorage office in 1993. He served as President and Director of Alscott, Inc. and The J.A. and Kathryn Albertson Foundation from 1993 until retirement in 2012. He also served as an officer in The United States Air Force, Air Force Reserve and Air National Guard from 1966 to retirement in 1989 as a Lt. Col. He has a Bachelor of Science and Master of Science in Business from the University of Minnesota and continuing education executive programs at Wharton and University of Southern California. His CPA license remains active in Idaho. His current Director positions include The Boise Rescue Mission Board, The Idaho Education Foundation Investment Board and The Idaho Defense Alliance Board.
    </p>
  </>
)

export default TomWilford

import React from "react"

const RichardMcPherson = (
  <>
  <h4>Lieutanant Commander US Navy (ret)</h4>
    <p>
    20 Years active duty including tours on Commander Pacific Fleet Propulsion Examination Board, and Board of Inspection and Survey. Then US Representative to the International Atomic Energy Agency. Executive VP of Down Range Global. Founder Western Institute of Agriculture, Water and Energy. CEO of Idaho Energy LLC.Submarine Service.
    </p>
  </>
)

export default RichardMcPherson

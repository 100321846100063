import React from "react"

const LindaOliver = (
  <>
    <p>
    <strong>Chairman - Crew and Family Support "SUB" Committee</strong><br />
    Native of Blackfoot Idaho. Attorney licensed in six jurisdictions, including Idaho. Qualified in mediation. Senior Executive Service member that directed the OSD Small Business Office. Lived in seven states and Japan as a Navy spouse. Traveled extensively overseas and in the US for DoD.
    </p>
  </>
)

export default LindaOliver

import React from "react"

const DennisJohnson = (
  <>
    <p>
    <strong>Retired — President and CEO United Heritage Mutual Holding Company</strong><br />
    Beginning his career as General Counsel of United Heritage Life in 1983, Mr. Johnson was promoted to President and CEO in 1999, a role in which he served for an additional 22 years. He is a past Chairman of the Boise Metro Chamber and the Boise Valley Economic Partnership.  Mr. Johnson has a BA from Northwest Nazarene University and a JD from the University of Idaho, College of Law.
    </p>
  </>
)

export default DennisJohnson

import React from "react"

const RichardColburn = (
  <>
  <h4>CAPT US Navy (ret)</h4>
    <p>
    Eight years active &amp; 23 years reserve duty. Served on USS Ohio SSBN 726 Gold. Hometown Parma Idaho. University of Idaho , BS Chem E 1987. MBA Wharton School of Business 2005. Twenty years high tech operations, manufacturing and renewable energy with Applied Materials, Solo Power, Vestas Wind Systems and Cymer Light Source LLC.

    </p>
  </>
)

export default RichardColburn

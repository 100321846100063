import React from "react"

const RayStark = (
  <>
  <p><strong>Senior Vice President Boise Metro Chamber (retired)</strong><br />
  Mr. Stark advocated for local, state and federal business issues during his 30 year career with the Boise Metro Chamber.  Prior to this he worked 12 years on the state budget staff for the Idaho Legislature.</p>
  <p>Ray serves on boards for Bishop Kelly High School, the University of Idaho, Boise State University and the Boise Philharmonic.</p>
  <p>Ray has Bachelor degrees in Political Science and History and a Masters of Public Administration (MPA) from the University of Idaho.</p>
  </>
)

export default RayStark

import React from "react"

const JackBurdick = (
  <>
    <p>
    <strong>Crew/Family Support SUB Committee</strong><br />
    Served over 21 years in the United States Navy Submarine  force with tours of duty on USS Daniel Boone SSBN629, USS Seawolf SSN575. In 1984 I retired as Chief of the Boat on USS Pogy SSN647. Following my Naval service I joined Southern California Edison as a Field Engineer. After serving  21 years with Edison I retired as Manager of the Combined Maintenance Facility. In 2005 I left the workforce and moved to Boise Idaho
    </p>
  </>
)

export default JackBurdick

import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import { Container, Row, Col } from "react-bootstrap"

import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle/PageTitle"
import ContentBlock from "../components/ContentBlock/ContentBlock"
import NewsCardsSidebar from "../components/NewsBlock/NewsCardsSidebar"
import EventsCardsSidebar from "../components/EventsBlock/EventsCardsSidebar"
import ContactForm from "../components/ContactForm/ContactForm"

import AdvisoryBoard from "../components/AdvisoryBoard/AdvisoryBoard"
import BoardOfDirectors from "../components/AdvisoryBoard/BoardOfDirectors"

import SupplyOfficer from "../images/supply-officer.jpg"
import GoldDolphin from "../images/gold-dolphin.jpg"
import ExecutiveCommittees from "../components/AdvisoryBoard/ExecutiveCommittees"
import Regionalcommittees from "../components/AdvisoryBoard/RegionalCommittees"

import ModalImage from 'react-modal-image'

const VisionMission = ({ data }) => {
  const page = data.allContentfulAboutUs.edges[0].node
  console.log(page)

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: { fields },
        },
      }) => (
        <div
          dangerouslySetInnerHTML={{
            __html: `<img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="fluid" style="width: 100%; margin: 20px 0 20px 0" />`,
          }}
        />
      ),

      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const fields = node.data.target.fields.imageLink
        const imgLnk = node.data.target.fields.url
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: `<a href="${imgLnk["en-US"]}"><img src="${fields["en-US"].fields.file["en-US"].url}" alt="${fields.title}" style="width: 100%;" /></a>`,
            }}
          />
        )
      },
    },
  }

  return (
    <Layout>
      <SEO title="About Us" />
      <Container fluid className="container-fluid-full">
        <PageTitle subPage="About Us" pageTitle="About Us" link="/">
          {/* {page.pageTitle} */}
        </PageTitle>
        <ContentBlock>
          <Row>
            <Col sm={9}>
              <div id="vision-mission">
                <h1>Vision Statement</h1>
                <p>Bring together the people of the great State of Idaho and the Officers and Crew of the USS IDAHO to celebrate in exemplary fashion the extraordinary honor of having a ship of the line named for the state. Create a bond between the people of Idaho and the sailors of the submarine that will last throughout the life of the ship and beyond. Recognize with great honor, the men and women that have served and will serve throughout the history of the land we now call Idaho.</p>
                <p className="text-center"><strong>Esto Perpetua</strong></p>
                <hr />
                <p className="blog-text">
                  {documentToReactComponents(
                    page.childContentfulAboutUsVisionMissionRichTextNode.json,
                    options
                  )}
                </p>
              </div>
              <hr />

              

            </Col>
            <Col sm={3}>
              <h1><Link to="/news">USS IDAHO News</Link></h1>
              <NewsCardsSidebar />
              
              <h1><Link to="/events">USS IDAHO Events</Link></h1>
              <EventsCardsSidebar />
              
            </Col>
          </Row>

          <div id="advisory-board" className="jumpTarget my-4">
                <h1 className="my-4">Advisory Board</h1>
                  <AdvisoryBoard />
              </div>

              <hr />

              <div id="commissioning-committee" className="jumpTarget">
                <h1>Commissioning Committee</h1>
                <h3 className="text-center">Foundation Board of Directors</h3>
                  <BoardOfDirectors />
                <hr />
                <h3 className="text-center my-4">Executive Committees</h3>
                <ExecutiveCommittees />
<hr />
                    <Row className="my-2">
                      <Col>
                      <h3 className="text-center">Regional Committees</h3>
                      </Col>
                    </Row>

                <Row className="mb-2">
                
                  <Col sm={8}>
                  <Regionalcommittees />    

                  </Col>
                  <Col sm={4}>
                    <Img fluid={data.idahoMap.childImageSharp.fluid} alt="Idaho Regions Map" />
                  </Col>
                </Row>

                {/* <p className="blog-text">
                  {documentToReactComponents(page.commissioning.json, options)}
                </p> */}
              </div>
                    <hr />
              <div id="endorsements" className="jumpTarget">
                <h1>Proclamations &amp; Endorsements</h1>
                <Row>
                  <Col md={3}>
                  <div>
                    <ModalImage
                      hideDownload
                      hideZoom 
                      small={data.idahoLegislature.childImageSharp.fluid.srcWebp}
                      large={data.idahoLegislature.childImageSharp.fluid.srcWebp}
                      alt="Idaho Legislature"
                      className="rounded"
                    />  
                    </div>
                  </Col>
                  <Col md={3}>
                    <div>
                    <ModalImage
                      hideDownload
                      hideZoom 
                      small={data.governorLittleProclamation.childImageSharp.fluid.srcWebp}
                      large={data.governorLittleProclamation.childImageSharp.fluid.srcWebp}
                      alt="Proclamation from Idaho Governor, Brad Little"
                      className="rounded"
                    />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div>
                    <ModalImage
                      hideDownload
                      hideZoom 
                      small={data.pocatelloProclamation.childImageSharp.fluid.srcWebp}
                      large={data.pocatelloProclamation.childImageSharp.fluid.srcWebp}
                      alt="Pocatello Proclamation"
                      className="rounded"
                    />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div>
                    <ModalImage
                      hideDownload
                      hideZoom 
                      small={data.darResolution.childImageSharp.fluid.srcWebp}
                      large={data.darResolution.childImageSharp.fluid.srcWebp}
                      alt="Daughters of the American Revolution Resolution"
                      className="rounded"
                    />
                    </div>
                  </Col>
                </Row>
              </div>
                <hr />
                <div id="committee-crest" className="jumpTarget">
                <h1>Committee Crest</h1>
                    <Row>
                      <Col sm={2}>
                      <Img fixed={data.shipsCrest.childImageSharp.fixed} alt="USS IDAHO Commissioning Committee Crest" />
                      </Col>
                      <Col sm={10}>
                      <p className="blog-text">
                        The Crest of the USS IDAHO Commissioning Committee contains icons of the State of Idaho and the US Navy submarine force.
                      </p>
                      <p>USS IDAHO SSN 799 is the official name and hull number of the future submarine names for Idaho.</p>
                      <p>The five gold stars represent that this submarine will be the fifth ship to be named USS IDAHO in the history of the navy.</p>
                      <p>The red, white and blue represent the iconic license plates of the state.</p>
                      <p>The submarine is depicted sailing on Lake Pend Oreille with the mountains in the background. Lake Pend Oreille is home to Acoustic Research Detachment Bayview, which is the Navy’s Premier Acoustic Laboratory and where the acoustic stealth technology of USS IDAHO was developed.</p>
                      <p>The outlines of the State and State Seal can be seen on the left and right. The moniker "Gem of the Fleet" comes for the namesake being known as the Gem State for its rich mineral resources and 72 different kinds of precious and semi precious stones that can be found throughout the state.</p>
                      <h3>About the Artist</h3>
                      <p>Nicole Hulse is a sophomore at Idaho State University. She plans to get her Bachelor's in Business Administration at ISU and currently double majors in marketing and management. As a marketing intern at ISU's College of Business, she also designs promotional material as well as run the college's blog. In between full-time school and work, Nicole enjoys creating art as a hobby and gets commissions from those who would like to buy her paintings. Her grandfather, Electricians Mate Chief Donald Hulse, served on six submarines during his 20-year navy career.</p>
                      
                      </Col>
                    </Row>
               
              </div>
                <hr />
              <div id="contact-us" className="display-card-box mb-4 jumpTarget">
              <h1>Contact Us</h1>
              <div>
            <p style={{
              fontSize: 'larger',
              textAlign: 'center'
            }}>
          USS IDAHO Commissioning Foundation Inc.<br />
PO Box 6077<br />
Boise ID 83708</p>
{/* <p style={{color: '#e76800'}}>All fields are required.</p> */}
          </div>
              <ContactForm />
              </div>
        </ContentBlock>
        
      </Container>

    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulAboutUs {
      edges {
        node {
          childContentfulAboutUsVisionMissionRichTextNode {
            json
          }
          advisory: childContentfulAboutUsAdvisoryBoardRichTextNode {
            json
          }
          commissioning: childContentfulAboutUsCommissioningCommitteeRichTextNode {
            json
          }
          commissioning: childContentfulAboutUsCommissioningCommitteeRichTextNode {
            json
          }
          endorsements: childContentfulAboutUsEndorsementsRichTextNode {
            json
          }
        }
      }
    }
    idahoLegislature: file(relativePath:{eq: "IDAHOLegislatureHCR003APPROVED01MAR2021.jpg"}) {
      childImageSharp {
        fluid(quality:80) {
          srcWebp
        }
      }
    }
    governorLittleProclamation: file(relativePath:{eq: "Governor-Little-Proclimation-26-JAN-2022.jpg"}) {
      childImageSharp {
        fluid(quality:80) {
          srcWebp
        }
      }
    }
    pocatelloProclamation: file(relativePath:{eq: "PocatelloProclamation-USS-Idaho-Day.jpg"}) {
      childImageSharp {
        fluid(quality:80) {
          srcWebp
        }
      }
    }
    darResolution: file(relativePath:{eq: "DAR-Resolution.jpg"}) {
      childImageSharp {
        fluid(quality:80) {
          srcWebp
        }
      }
    }
   idahoMap: file(relativePath:{eq: "idaho-region-map.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shipsCrest: file(relativePath:{eq: "USS-Idaho-SSN-799.png"}) {
      childImageSharp {
        fixed(width: 200, quality: 90)  {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default VisionMission

import React from "react"

const AndyKrivy = (
  <>
  <h4>Fire Control Technician Second Class (FTB2), US Navy Veteran</h4>
    <p>
    Six years active duty. USS Lafayette SSBN616. Hometown Flint Michigan. 20 years working at Micron. Small business owner, Infinity Signs. 8 years Navy Reserve.
    </p>
  </>
)

export default AndyKrivy

import React from "react"

const GregoryMcDonald = (
  <>
    <p>
    <strong>U.S. Navy Veteran</strong><br />
    Bio coming soon.
    </p>
  </>
)

export default GregoryMcDonald


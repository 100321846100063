import React from "react"

const RobertEarle = (
  <>
    <p>
    <strong>LCDR US Navy Veteran</strong><br />
    Nine years active, Seven years reserve. Served on the USS Thomas Jefferson SSN 618 and at The Navy Command Center in the Pentagon. Michigan native now living in Boise, ID. Degrees from Washington &amp; Lee University, BS 1980, in Physics and Applied Engineering, and Harvard University, MBA 1990. Career positions have included Vice President at JPMorgan Chase, Managing Director at PricewaterhouseCoopers and Partner at IBM focused in high tech/consulting.</p>
  </>
)

export default RobertEarle

import React from "react"

const RobertSkinner = (
  <>
  <h4>Vice Chair Central Area SE Region, CAPT US Navy (ret)</h4>
    <p>
    Bob started his US Navy career in 1968 and was a prototype instructor and on nuclear submarines.  He followed his active duty by joining the Naval Reserves and retired in 2007 at the rank of Captain. He also spent 26 years at the Idaho National Laboratory and retired in 2006. Bob volunteers many hours helping Veterans and serves with many Veterans Service Organizations.
    </p>
  </>
)

export default RobertSkinner

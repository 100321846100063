import React from "react"

const MarkusGundmundsson = (
  <>
    <p>
    <strong>Captain, U.S. Navy (ret)</strong><br />
    </p>
    <p>
    Captain Gudmundsson retired after a 30-year career in the U.S. Navy in 2022. He commanded squadrons and flew in FA-18F carrier-based jets in combat missions supporting Operation ENDURING FREEDOM and Operation SOUTHERN WATCH. His final tour was at the Naval Postgraduate School in Monterey, CA where he served as the Commanding Officer of the student population and as the Dean of Students.</p>
    <p>Captain Gudmundsson has over 800 arrested landings on aircraft carriers and over 3,700 flight hours in multiple platforms, including over 2,000 hours in the FA-18F. He is authorized to wear the Legion of Merit, Bronze Star, Defense Meritorious Service Medal, Meritorious Medal (3), Strike Flight Air Medal (4), and various personal, campaign, and unit awards.</p>
    <p>He graduated from Boise High School in 1987. He attended the U.S. Naval Academy in Annapolis, Maryland where he earned a Bachelor of Science degree in May of 1991. He holds a Master of Science degree from the University of San Diego, and a Master of Arts degree from the Naval War College.</p>
  </>
)

export default MarkusGundmundsson

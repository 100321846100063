import React from "react"

const BillShawver = (
  <>
  <h4>Brig Gen U.S. Air Force (ret)</h4>
    <p>
    Brig Gen Shawver retired from the Idaho National Guard as the Director of the Idaho Bureau of Homeland Security and the Commander of the Idaho Air National Guard.  He served as the Idaho Power Director of Communication from 2012 until he retired in 2017 and Co-Founded Atlas Communications.  Brig Gen Shawver has served in numerous volunteer positions, including a key role appointed by the Governor to the Idaho Military Advocacy Commission and Chairman of the Idaho Homeland Security Advisory Council, the Idaho Defense Alliance and the Wyakin Foundation.
    </p>
  </>
)

export default BillShawver

import React from "react"

const BarryOglesbee = (
  <>
  <h4>Electricians Mate Nuclear First Class (EM1) US Navy Veteran</h4>
    <p>Dr. Oglesbee received his doctorate degree from Palmer College of Chiropractic, Davenport, Iowa in 1995. He is an alumni member of the Delta Sigma Chi professional chiropractic fraternity, Alpha chapter, currently serving on the Grand Council Board of Directors.</p>
    <p>Prior to his chiropractic college training, Dr. Oglesbee served on active duty with the U.S. Navy for seven years. His responsibilities included nuclear propulsion plant operations/electrician on the submarines USS George C. Marshall, and USS Pasadena, at the rank of First Class Petty Officer (E-6).</p>
    <p>He also holds a Bachelors of Science degree from the University of the State of New York, in Albany, New York as well as a Masters of Theology degree from the America Bible Institute, Olive Branch Mississippi. Dr. Oglesbee is an ordained minister. He is a member of the United States Submarine Veterans Association, U.S. Navy Submarine League, and Veterans of Foreign Wars. Dr. Oglesbee and his wife Gail, a 34-year registered nurse, have been married for 20 years.</p>
  </>
)

export default BarryOglesbee

import React from "react"

const AndrewJohnson = (
  <>
  <h4>SSGT US Marine Corps Veteran</h4>
    <p>
    Eight years active-duty USMC. Deployed as a 0351 Infantry Assaultman with 2 nd Bn. 3 rd Mar. Div. on the 31 st MEU aboard the USS Essex (LHD-2) and deployed with the same battalion to Afghanistan. Later served as a 0211 Counterintelligence/Human Intelligence Specialist. Hometown Caldwell Idaho. BA Homeland Security, American Military University. MS Accountancy-Taxation, Boise State University. JD, University of Idaho. LLM (Tax), Georgetown Law. Currently practices law in the Treasure Valley.
    </p>
  </>
)

export default AndrewJohnson

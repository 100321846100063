import React from "react"

const RichardFife = (
  <>
  <h4>Lieutenant Commander US Navy (ret)</h4>
    <p>
    20 years active duty. Hometown Hailey Idaho. University of Idaho, BS Chemistry 1973. MBA City University 1998. Strategic Warfare System Officer, served Navigator, qualified Command at Sea.   Nine years operations management with Washington State Ferries.

    </p>
  </>
)

export default RichardFife

import React from "react"

const DougArmstrong = (
  <>
  <h4>Corporate Fundraising SUB Committee</h4>
    <p>
    Doug is currently the Chaplain of the Idaho State Senate. He retired in 2018 after 22 years as President &amp; General Manager of NBC KTVB Channel 7 in Boise. After retirement Doug earned a Masters Degree in Biblical Studies from Chicago's Moody Theological Seminary in 2019.
    </p>
  </>
)

export default DougArmstrong

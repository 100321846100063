import React from "react"

const RonEythchison = (
  <>
    <p>
    <strong>U.S. Navy (ret)</strong><br />
    <p>Vice Admiral Eytchison is a native of Boise ID, graduating from Boise High School in 1954 and then went on to graduate from the Naval Academy with distinction in 1958.  In his 33 year Navy career he commanded Submarine Group 8, Submarine Squadron 6 and USS Skate SSN 578.</p>
    <p>He was recognized as the Naval Submarine League Distinguished Submariner for 2020 Vice Admiral Eytchison currently resides in Signal Mountain Tennessee.</p>
    <p><a href="https://www.history.navy.mil/research/library/research-guides/modern-biographical-files-ndl/modern-bios-e/eytchison-ronald-m.html" target="_blank" rel="noopener noreferrer">Navy History and Heritage Command BIO</a></p>
    </p>
  </>
)

export default RonEythchison

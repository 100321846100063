import React from "react"

const LauraBarton = (
  <>
    <p>
    Laura Barton has directed communications, public affairs, and international advocacy at the highest levels of government and philanthropy, including the White House Office of Global Communications and the Bill & Melinda Gates Foundation. As the Editorial and Web Manager for the Bill & Melinda Gates Foundation, Laura provided strategic direction and oversight of the speeches of Bill and Melinda Gates, and other foundation executives. She developed the foundation’s messaging, publications, videos, and web site content.  In the wake of the 9/11 terrorist attacks, she directed the communication of the U.S. government’s relief and reconstruction efforts in Afghanistan to a wide variety of national and international audiences at the White House Office of Global Communications. Laura developed messaging about humanitarian assistance to Afghanistan for the President, the First Lady, and for other high-level government officials. Additionally, she conducted media outreach to top-tier national and international news organizations. Laura served as the director of advocacy campaigns for World Vision, the world’s largest privately funded relief, development, and advocacy organization. Laura is an Idaho native and graduated cum laude from Duke University with a BA in international studies. She has traveled extensively throughout Russia and the developing world and speaks Russian. She serves on the executive board of the Daughters of the American Revolution’s Pioneer Chapter and is a member of the Idaho Advisory Committee of the U.S. Global Leadership Coalition, the American Committees on Foreign Relations, the Boise Committee on Foreign Relations, and the Idaho Women’s Charitable Foundation.


    </p>
  </>
)

export default LauraBarton

import React from "react"

const MikeFeeley = (
  <>
  <h4>CAPT US Navy (ret)</h4>
    <p>
    Captain US Navy (ret). 27 years active duty. Served on USS John Marshall (SSBN 611), USS Drum (SSN 677), USS Benjamin Franklin (SSBN 640) and USS Memphis (SSN 691). Commanded USS Phoenix (SSN 702) and Submarine Squadron 3. US Naval Academy, BS Operations Research 1971. Vice President Business Development, Lockheed Martin Maritime Systems 1998-2011. Retired and living in Boise, Idaho.
    </p>
  </>
)

export default MikeFeeley

import React from "react"

const ScottTurlington = (
  <>
  <p><strong>President, Tamarack Resort</strong><br />
  Scott is the President of Tamarack Resort, located two hours north of Boise, on the western shores of Lake Cascade. He began his career in 1999 as a U.S. Senate staffer for Senator Larry Craig. In 2001 he moved to Boise as a Senior Policy Advisor for then Governor Dirk Kempthorne. He left Governor Kempthorne's office in 2004 to pursue a career in the private sector. He was a co-founder of a Public Policy consulting firm in Boise until April of 2020 when he left to return to Tamarack Resort as the President. Scott has a BS in Political Science from Idaho State University and a Masters in Public Administration and Policy from Idaho State University.  
</p>
  </>
)

export default ScottTurlington

import React from "react"

const SueThilo = (
  <>
  <p><strong>Corporate Fundraising “SUB” Committee</strong><br />
  Sue Thilo began her career in the communications industry and retired in 2009 as business manager for Thomas K. Thilo, M.D., general surgeon.  She served on the Idaho State Board of Education in 2004 – 2009 and is past chair of the Idaho Community Foundation. She currently serves on the boards of North Idaho College Foundation, the Salvation Army Kroc Center Advisory Board, and the Coeur d’Alene Rotary Endowment Foundation.</p>
  </>
)

export default SueThilo

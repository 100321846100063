import React from "react"

const SteveHillis = (
  <>
    <p>
    <strong>Crew/Family Support SUB Committee</strong><br />
    </p>
    <ul>
<li>Pacific Fleet Master Chief – 1998-2000</li>
<li>Command Master Chief USS Nimitz, Submarine Squadron 17</li>
<li>COB USS Ohio SSBN 726</li>
<li>BS Southern Illinois University </li>
<li>Graduate Degrees Old Dominion University, Doane University</li>
<li>VP Acuitus, Dean Florida State College at Jacksonville</li>
    </ul>
  </>
)

export default SteveHillis

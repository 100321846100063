import React from "react"

const DonHulse = (
  <>
  <h4>Vice Chair Southern Area SE Region
EMC (ss) US Navy (ret)</h4>
    <p>
    Twenty years on active duty in the Navy. Went through nuclear prototype training at NPTU Idaho. Served on four different submarines, did 23 deterrent patrols and retired from the Navy in 1975. Was the world-wide Operations Manager for American Microsystems, Inc. (now ON Semiconductor) when he fully retired in 1999. Don lives in Pocatello, Idaho.
    </p>
  </>
)

export default DonHulse

import React from "react"
import { Row, Col } from "react-bootstrap"
import CrewModal from "./AdvisoryBoardModal"

function Modal(props) {
  const [modalShow, setModalShow] = React.useState(false)

  return (
    <>
      <div
        onClick={() => setModalShow(true)}
        className="boardOfDirectorsModalButton"
      >
       { props.teamMemberPhoto ? (
       <div
        className="team-member-photo">
          <img
            className="rounded "
            src={props.teamMemberPhoto}
            style={{
              width: "100px",
            }}
          />
        </div> ) : null } 
        <div
        className="crew-block"
        style={{
                display: 'flex',
                paddingLeft: '0.5rem',
            }}
            >
            <div className="crew-name">
          {props.crewName}
          
          <br />
          
          { props.position ? (<> <span
            style={{
              fontWeight: "normal",
              fontSize: "16px",
            }}
          > {props.position} </span> <br /> </> ) : null }
         
         <span
            style={{
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {props.rank}
            </span>
          
        </div>

        <div className="emblem">
          {props.emblem ? (<img
            src={props.emblem}
            style={{
              width: "120px",
              marginRight: '25px',
              marginLeft: '10px'
            }}
          /> ) : null}
        </div>
        </div>
      </div>
      <CrewModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        position={props.position}
        crewName={props.crewName}
        modalContent={props.modalContent}
        teamMemberPhoto={props.teamMemberPhoto}
        emblem={props.emblem}
      />
    </>
  )
}

export default Modal

import React from "react"

const RobertBrady = (
  <>
    <p>
    <strong>LT, U.S. Navy (ret)</strong><br />
    </p>
    <p>LT Robert Brady served on the USS Dace (SSN-607) from 1970 through 1976. He is a member of United States Submarine Veterans Inc.(USSVI), USS Boise (SSN-764) Base</p>
  </>
)

export default RobertBrady

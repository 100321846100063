import React from "react"

const PhilReberger = (
  <>
    <p>
    <strong>U.S. Navy (ret)</strong><br />
    A native of Caldwell, Idaho, and NROTC graduate of the University of Idaho.</p>
    <p>Served 23 years as active-duty Naval Aviator and staff pilot for Admiral John McCain, CINCPAC, with subsequent assignments as Navy Reserve Security Group unit Commanding Officer, retiring as Chief of Staff to Commander Navy Reserve Security Group.</p>
    <p>In civilian life served as campaign manager and Chief of Staff to four United States Senators and two Governors, including Idaho Senators McClure, Symms and Kempthorne. Retired in 2003 as Governor Kempthorne's Chief of Staff.</p>
    <p>Currently a partner of Sullivan &amp; Reberger, a leading Boise government relations firm.
    </p>
  </>
)

export default PhilReberger

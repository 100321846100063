import React from "react"

const MikeJohnson = (
  <>
  <p>
  Mike Johnson was born and raised in Idaho and has a long public service career. Mike has served as Boise County Deputy Coroner and Deputy Sheriff, Ada County Coroner and Commissioner, and served as US Marshal for the District of Idaho. He ended his career serving as Boise Airport Chief of Police. Mike has a Criminal Justice Administration degree from Boise State University and is a graduate of the Idaho Peace Officer Training Academy, FBI National Academy, and Harvard University State and Local Government Executive Program. Mike served on the USS Boise Committee. He is past president and founder of the Idaho Coroner’s Association. He also served as past president of the Idaho/Oregon Peace Officer’s Association and the Idaho Chief’s of Police Association. He served as Board President of the Idaho Peace Officer’s Memorial. 
</p>
  </>
)

export default MikeJohnson

import React from "react"

const JohnGroseenbacher = (
  <>
    <p>
    <strong>U.S. Navy (ret)</strong><br />
    Vice Admiral Grossenbacher is the former Director of the Idaho National Laboratory and President of Battelle Energy Alliance, LLC. In his 33 year Navy career he commanded US Submarine Forces, Submarine Group 8, Submarine Squadron 8 and USS Barb SSN 596. He is a 1970 graduate from the US Naval Academy.
    </p>
  </>
)

export default JohnGroseenbacher

import React from "react"

const MelanieNeilson = (
  <>
  <h4>Chairman - Marketing and Public Affairs Committee</h4>
    <p>
    </p>
  </>
)

export default MelanieNeilson

import React from "react"

const TimMarsano = (
  <>
  <h4>Col USAF (ret)</h4>
    <p>
    Tim Marsano was commissioned by the U.S. Air Force in 1983 after completing a degree and the ROTC program at the University of Massachusetts.  He served in the Air Force Office of Special Investigations as a criminal investigations officer before signing on with the Idaho Air National Guard in 1989.  While with the Idaho National Guard, he served as an intelligence officer, plans/training officer and public affairs officer, retiring as a colonel in 2015.
    </p>
  </>
)

export default TimMarsano

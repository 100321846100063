import React from "react"

const DavidMericle = (
  <>
    <p>
    <strong>U.S. Navy (ret)</strong><br />
    Capt. Mericle, a 1973 graduate of the Naval Academy, served on five submarines and was the commissioning commanding officer of USS Boise (SSN 764). His final active duty assignment was Director for Maintenance, Submarine Force, Atlantic. A California native, he and his wife, Julie, now reside in Newport News, VA. They have three married sons and have been blessed with twelve grandchildren.
    </p>
  </>
)

export default DavidMericle
